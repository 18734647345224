// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_f7a5865","type-body-2":"type-body-2_f4e059a","type-body-3":"type-body-3_da6299e","type-body-4":"type-body-4_bf5d389","type-section-title":"type-section-title_c359d1d","type-notes":"type-notes_d30d963","type-item-title":"type-item-title_d3cca98","type-item-subtitle":"type-item-subtitle_b2b1ebe","type-subtitle":"type-subtitle_b28e04b","type-title":"type-title_d4dc776","title":"title_ae5e1a1","confirmDialog":"confirmDialog_a31669e","header":"header_abf61fc","body":"body_a9c906e","actionButton":"actionButton_f8620dc","button":"button_d4d1750","closeButton":"closeButton_e983e0c","sprite":"sprite_f42d25e","spinnerContainer":"spinnerContainer_efcee24","footer":"footer_a061bc3","bringToFront":"bringToFront_c8f719b"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_f7a5865\",\"type-body-2\":\"type-body-2_f4e059a\",\"type-body-3\":\"type-body-3_da6299e\",\"type-body-4\":\"type-body-4_bf5d389\",\"type-section-title\":\"type-section-title_c359d1d\",\"type-notes\":\"type-notes_d30d963\",\"type-item-title\":\"type-item-title_d3cca98\",\"type-item-subtitle\":\"type-item-subtitle_b2b1ebe\",\"type-subtitle\":\"type-subtitle_b28e04b\",\"type-title\":\"type-title_d4dc776\",\"title\":\"title_ae5e1a1\",\"confirmDialog\":\"confirmDialog_a31669e\",\"header\":\"header_abf61fc\",\"body\":\"body_a9c906e\",\"actionButton\":\"actionButton_f8620dc\",\"button\":\"button_d4d1750\",\"closeButton\":\"closeButton_e983e0c\",\"sprite\":\"sprite_f42d25e\",\"spinnerContainer\":\"spinnerContainer_efcee24\",\"footer\":\"footer_a061bc3\",\"bringToFront\":\"bringToFront_c8f719b\"}";
        // 1734200867787
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  