// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","button":"button_adc4b3c","input":"input_ed95884","primaryAuthButton":"primaryAuthButton_deb3362","secondaryAuthButton":"secondaryAuthButton_fbae41c","errors":"errors_f06733b","authForm":"authForm_e25ef46","header":"header_c046765","subHeader":"subHeader_b55de55","altLink":"altLink_df69e6d","successText":"successText_c1cf31b","textBody":"textBody_e022183","inputGroup":"inputGroup_d352318","inputSection":"inputSection_cf646d6","passwordInputContainer":"passwordInputContainer_f35ed93","showHidePass":"showHidePass_f03c202","showHideGlyph":"showHideGlyph_c6f240b","loadingSpinner":"loadingSpinner_fb9fb8a","successIcon":"successIcon_ad3ec4f","altLinks":"altLinks_d7441fe","poweredBy":"poweredBy_d994369","bannerMessage":"bannerMessage_d703fc9"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"button\":\"button_adc4b3c\",\"input\":\"input_ed95884\",\"primaryAuthButton\":\"primaryAuthButton_deb3362\",\"secondaryAuthButton\":\"secondaryAuthButton_fbae41c\",\"errors\":\"errors_f06733b\",\"authForm\":\"authForm_e25ef46\",\"header\":\"header_c046765\",\"subHeader\":\"subHeader_b55de55\",\"altLink\":\"altLink_df69e6d\",\"successText\":\"successText_c1cf31b\",\"textBody\":\"textBody_e022183\",\"inputGroup\":\"inputGroup_d352318\",\"inputSection\":\"inputSection_cf646d6\",\"passwordInputContainer\":\"passwordInputContainer_f35ed93\",\"showHidePass\":\"showHidePass_f03c202\",\"showHideGlyph\":\"showHideGlyph_c6f240b\",\"loadingSpinner\":\"loadingSpinner_fb9fb8a\",\"successIcon\":\"successIcon_ad3ec4f\",\"altLinks\":\"altLinks_d7441fe\",\"poweredBy\":\"poweredBy_d994369\",\"bannerMessage\":\"bannerMessage_d703fc9\"}";
        // 1734200866938
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  