// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_a52b98d","type-body-2":"type-body-2_a6f2a96","type-body-3":"type-body-3_fff54ae","type-body-4":"type-body-4_f5bc0cb","type-section-title":"type-section-title_f5022b8","type-notes":"type-notes_d86f088","type-item-title":"type-item-title_b834b26","type-item-subtitle":"type-item-subtitle_b8d61a9","type-subtitle":"type-subtitle_ab5d885","type-title":"type-title_e92135a","channelButton":"channelButton_e41d759","channelName":"channelName_f905ead","hasUnreadMessage":"hasUnreadMessage_d3a4c9f","isSelected":"isSelected_b0a9c2a","isBroadcast":"isBroadcast_efea72b","unreadMessageCount":"unreadMessageCount_d4a696e"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_a52b98d\",\"type-body-2\":\"type-body-2_a6f2a96\",\"type-body-3\":\"type-body-3_fff54ae\",\"type-body-4\":\"type-body-4_f5bc0cb\",\"type-section-title\":\"type-section-title_f5022b8\",\"type-notes\":\"type-notes_d86f088\",\"type-item-title\":\"type-item-title_b834b26\",\"type-item-subtitle\":\"type-item-subtitle_b8d61a9\",\"type-subtitle\":\"type-subtitle_ab5d885\",\"type-title\":\"type-title_e92135a\",\"channelButton\":\"channelButton_e41d759\",\"channelName\":\"channelName_f905ead\",\"hasUnreadMessage\":\"hasUnreadMessage_d3a4c9f\",\"isSelected\":\"isSelected_b0a9c2a\",\"isBroadcast\":\"isBroadcast_efea72b\",\"unreadMessageCount\":\"unreadMessageCount_d4a696e\"}";
        // 1734200886433
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  